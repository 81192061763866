import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import { FaBars } from 'react-icons/fa';
import navItems from '../sections/navItems';

const NavItem = ({ id, name, isFirst, ...restProps}) => {
  return (
    <li className={`block md:inline-block pb-2 pt-2 md:pt-0 md:pb-0 ml-0 ${isFirst ? `md:ml-0` : `md:ml-4`} font-bold no-underline text-black hover:text-gray-600`}>
      <AnchorLink
        href={`#${id.toLowerCase()}`}
        offset="110"
        {...restProps}
      >
        {name}
      </AnchorLink>

    </li>
  )
}

const Nav = ({children, isExpanded}) => {
  return (
    <header className="bg-telekom-yellow-500 fixed z-50 top-0 w-full border-b border-black">
      <div className={`flex flex-wrap items-center justify-between max-w-4xl mx-auto px-4 pt-4 ${isExpanded ? `pb-2` : `pb-8`} md:px-6 md:pt-5 md:pb-8`}>
        {children}
      </div>
    </header>
  )
}

const ScrollspyContainer = (props) => {
  return (
    <ul {...props}/>
  );
}

const NavList = ({isExpanded, onClose}) => {
  return (
    <nav className={`${isExpanded ? `block` : `hidden`} self-end md:block md:flex md:items-center w-full md:w-auto`}>
      <Scrollspy
        className="p-2 pt-6 md:p-0"
        componentTag={ScrollspyContainer}
        currentClassName="text-blue-700 underline"
        items={navItems.map(item => item.id.toLowerCase())}
        offset={-150}
      >
        {navItems.map((navItem, index) => {
          const { id, name } = navItem;
          const isFirst = (index === 0);
          return (
            <NavItem id={id} isFirst={isFirst} key={id} name={name} onClick={onClose} />
          );
        })}
      </Scrollspy>
    </nav>
  );
}

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site: { siteMetadata: {title, telephone}} } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          telephone
        }
      }
    }
  `);

  const closeMobileMenu = () => {
    toggleExpansion(!isExpanded);
  };

  return (
    <Nav isExpanded={isExpanded}>
      <Link className="relative flex items-center no-underline text-white text-blue-700 font-logo" to="/">
        <span className="font-bold uppercase text-4xl tracking-tight">
          {title}
        </span>
        <span className="absolute -bottom-0.75 text-sm w-full tracking-wider text-center">
          {telephone}
        </span>
      </Link>

      <button
        className="block self-end md:hidden flex items-center hover:outline-none focus:outline-none px-3 py-2 text-black text-2xl"
        onClick={() => toggleExpansion(!isExpanded)}
      >
        <FaBars/>
      </button>

      <NavList isExpanded={isExpanded} onClose={closeMobileMenu}/>

    </Nav>
  );
}

export default Header;
