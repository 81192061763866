import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from './Footer';

function Layout({ withoutContainer, children }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header />

      <main className={`${withoutContainer ? `pt-20 sm:pt-22 md:pt-24` : `flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full`}`}>
        {children}
      </main>

      <Footer/>

    </div>
  );
}

Layout.defaultProps = {
  withoutContainer: false
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  withoutContainer: PropTypes.bool
};

export default Layout;
