import { graphql, useStaticQuery} from "gatsby";
import { FaPhone, FaEnvelope, FaLocationArrow, FaGlobe } from 'react-icons/fa';
import getDirectionsUrl from '../utils/getGoogleDirectionsUrl';
import React from "react";

const AddressOneLine = ({addressLine, className}) => {
  return (
    <span className={className}>
      {addressLine}
    </span>
  );
};

const AddressMultipleLines = ({className, ...addressParts}) => {
  const { addressLine1, addressLine2, addressCity, addressState, addressZip } = addressParts;
  return (
    <address className={className}>
      {`${addressLine1}${addressLine2}`} <br />
      {`${addressCity}, ${addressState}  ${addressZip}`}
    </address>
  )
};

const Address = ({ className, ...addressParts}) => {
  const { addressLine1, addressLine2, addressCity, addressState, addressZip } = addressParts;
  const addressLine = `${addressLine1}${addressLine2}, ${addressCity} ${addressState}, ${addressZip}`;
  const directionsURL = getDirectionsUrl(addressLine);
  return (
    <a className={`block flex items-center hover:text-gray-300 ${className}`} href={directionsURL} rel="noopener noreferrer" target="_blank">
      <AddressOneLine addressLine={addressLine} className="hidden sm:block"/>
      <AddressMultipleLines {...addressParts} className="flex-auto sm:hidden"/>
      <FaLocationArrow className="inline flex-1 ml-0 sm:ml-4"/>
    </a>
  );
}

const Stores = ({stores}) => {
  return stores.map((store, index) => {
    return (
      <Address
        {...store}
        className={(index === 0) ? `mb-8 sm:mb-4` : ``}
        key={index}
      />
    );
  });
};

const getHostnameFromRegex = (url) => {
  // run against regex
  const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  // extract hostname (will be null if no match is found)
  return matches && matches[1];
}

function Footer() {
  const { site: { siteMetadata: { telephone, stores, email, siteUrl}} } = useStaticQuery(graphql`
    query SiteFooterQuery {
      site {
        siteMetadata {
          siteUrl,
          stores {
            addressLine1,
            addressLine2,
            addressCity,
            addressState,
            addressZip
          },
          telephone,
          email
        }
      }
    }
  `);

  const url = getHostnameFromRegex(siteUrl);

  return (
    <footer className="bg-blue-700">
      <nav className="flex flex-wrap justify-between max-w-4xl mx-auto px-12 py-12 md:p-8 text-sm">
        <p className="pb-10 w-full sm:w-auto sm:pb-0 text-white flex flex-col content-center justify-center text-base">
          <Stores stores={stores}/>
        </p>
        <p className="text-white">
          <span className="font-bold text-xl block mb-5 sm:mb-3">
            Informacje kontaktowe
          </span>
          <a className="hover:text-gray-300 block mb-3 sm:mb-1" href={siteUrl} rel="noopener noreferrer" target="_blank">
            <FaGlobe className="inline-block mr-3" />
            <span>{url}</span>
          </a>
          <a className="hover:text-gray-300 block mb-3 sm:mb-1" href={`tel:${telephone}`}>
            <FaPhone className="inline-block mr-3"/>
            <span>{telephone}</span>
          </a>
          <a className="hover:text-gray-300 block" href={`mailto:${email}`}>
            <FaEnvelope className="inline-block mr-3"/>
            <span>{email}</span>
          </a>
        </p>
      </nav>
    </footer>
  );
}

export default Footer;
