import React, {Fragment} from 'react'

const PlansBodyName = () => {
  return (
    <Fragment>
      Nasze Plany Telefoniczne
      <span className="ml-2">*</span>
    </Fragment>
  )
}

const NAV_ITEMS = [
  { id: `welcome`, name: `Witamy`, bodyName: `Witamy`},
  { id: `services`, name: `Usługi`, bodyName: `Nasze Usługi`},
  { id: `plans`, name: `Plany`, bodyName: <PlansBodyName/>},
  { id: `faq`, name: `Pytanie`, bodyName: `Najczęściej zadawane pytania`},
  { id: `locations`, name: `Lokalizacje`, bodyName: `Lokalizacje` },
];

export default NAV_ITEMS;